import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <iframe
        frameborder="0"
        src="https://yeni.oteliza.com/blokaj/mayasapancabungalov"
        name="1726946231259"
        className="cboxIframe"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
    </div>
  );
}

export default App;
